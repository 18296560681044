<template>
  <base-info-card 
        title="Perfil"
        subtitle="Datos Usuario">
        <v-row>
            <v-col>
                Nombre
            </v-col>
            <v-spacer />
            <v-col class="text-subtitle-2">
                {{formUser.name}}
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                Username
            </v-col>
            <v-spacer />
            <v-col class="text-subtitle-2">
                {{formUser.email}}
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                Password
            </v-col>
            <v-spacer />
            <v-col class="text-subtitle-2">
                 <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                    content-class="dialog-class pa-5"
                    >
                    <template v-slot:activator="{ on, attrs }" v-bind="attrs" v-on="on">
                        <v-btn fab x-small @click="dialog = !dialog"><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            label="Please set your password"
                            hint="At least 8 characters"
                            counter
                            ref="p1"
                            @click:append="show1 = !show1"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="password2"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show2 ? 'text' : 'password'"
                            label="Repeat Password"
                            hint="At least 8 characters"
                            class="input-group--focused"
                            ref="p2"
                            @click:append="show2 = !show2"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <base-btn class="success" @click="savePass">Save</base-btn>
                        </v-col>
                        <v-col>
                            <base-btn class="error" @click="dialog = false">Close</base-btn>
                        </v-col>
                    </v-row>
                 </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                Numero de Dispositivos
            </v-col>
            <v-spacer />
            <v-col class="text-subtitle-2">
                {{formUser.devices.length}}
            </v-col>
        </v-row>
        <v-row>
            <v-data-table 
                :headers="headers"
                :items="formUser.devices"
                
                
            >
            <template v-slot:item.payDate="{ item }">
                    <span>{{ getDate(item) }}</span>
            </template>
            </v-data-table>
        </v-row>
    </base-info-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props:{
        formUser:{
            Type: Object,
            default:{devices:[]}
        }
    },
    data: ()=> ({
        headers:[
            {text:'Dispositivo',value:'name'},
            {text:'Fecha de Vencimiento', value:'payDate'}
        ],
        show1: false,
        show2: false,
        dialog: false,
        password: '',
        password2:'',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        }
    })
    ,methods:{
        ...mapActions(['updatePass','showSnackbar']),
        getDate(item){
            return this.$func.getDateString(item.payDate);
        },
        savePass(){
            
            if(this.password != this.password2){
                alert('Passwords does not match.');
                return;
            }
            if(!this.$refs.p1.valid){
                alert('Passwords is not valid.');
                return;
            }

            //Send update password
            var uid = this.$route.params?.id || this.$store.state.user.id;
            var user = {
                id: uid,
                password: this.password
            }
            this.updatePass(user).then(() =>{
                this.showSnackbar({
                    showing:true,
                    message: 'Password updated.',
                    color:'success'
                });
            }).catch(err =>{ 
                console.log(err);
                    if(err.data.errors)
                        this.showSnackbar({
                            showing:true,
                            message: err.data.errors[0],
                            color:'error'
                        });
                    else
                        this.showSnackbar({
                            showing:true,
                            message: 'Error saving contact.',
                            color:'error'
                        });
            });

            this.dialog = false;
        }
    }
}
</script>

<style>

</style>